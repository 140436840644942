<template>
    <div id="instalist-wrapper">
        <h4>Instaposts</h4>
        <ul>
          <li :class="isActive(index) ? 'active' : ''" @click="openPost(index)" v-for="p, index in myList" :key="index">
              <span>{{ p.datum }}</span>
              <p v-if="p.show" >{{ p.post }}</p>
          </li>
        </ul>
      </div>
</template>
<script>
import { bus } from '../main'

export default {
  name: 'InstaPosts',
  data () {
    return {
        myList: this.getList(),
        allInstaImages: []
      }
  },
  created() {
          this.axios
      .get("/insta/insta_images.json", { 
      })
      .then(result => {
          //console.log(result);
          this.allInstaImages = result.data;
      })
      .catch((e) => { 
        console.log(e.message);
      });
  },
  methods: {
    say(msg) {
        this.status = msg;
        setTimeout(() => { this.status = ''}, 5000);
    },
    isActive(i) {
        return this.myList[i].show;
    },
    //json file van specifieke datum ophalen en caption tonen
    openPost(i) {      
      this.myList.forEach((element, index) => {
          this.myList[index].show = false;
      });
      const instaImagesDate = this.allInstaImages.filter(x => { return x.substring(0,10) == this.myList[i].datum });
      //console.log(instaImagesDate);
      var datestr = this.myList[i].datum.replaceAll('-','_');
      //
      this.axios
      .get("/insta/the_walking_home_project" + datestr + ".json", { 
      })
      .then(result => {
        //if (result) {
            //console.log(result);
            this.myList[i].post = '';
            result.data.forEach(p => {
                this.myList[i].post += '\n' + (!this.isMobile() ? 'Read the story in the popup' : p.caption);
                try {
                  if (p.location == undefined) {
                    var markerInfo = { desc: 'undefined', lat: 0, lng: 0, images: instaImagesDate, url: p.url  };
                  }
                  else {
                    markerInfo =  { desc: '<strong>' + p.location.name[2] + '</strong><p class="insta-story">' + (!this.isMobile() ? p.caption : '')  + '</p>', lat: p.location.lat, lng: p.location.lng, images: instaImagesDate, url: p.url };
                  }
                  bus.$emit('setInstaMarker', markerInfo);
                }
                catch(err) {
                  console.log(err.message);
                }
            });
            if (this.myList[i].post == '') {
              this.myList[i].post = 'Nothing posted...';
            }
        //}
      })
      .catch((e) => { 
          this.myList[i].post = 'No insta post found/empty ' + e.message;
      });

      this.myList[i].show = !this.myList[i].show;
    },
    getList() {
      var dates = this.getDatesBetween(new Date('2022-07-07'), new Date('2024-08-22'), false);
      var arrList = [];
      dates.forEach(d => {
          //if (arrList.find(dd => dd.d == d.toISOString().slice(0,10)) === undefined)
          arrList.push({datum: d.toISOString().slice(0,10), post: '', show: false});
      })
      return arrList;
    },
    getDatesBetween (startDate, endDate, includeStartDate) {
    const dates = [];
    const currentDate = endDate;
    while (currentDate > startDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() -1);
    }
    
    if (includeStartDate) dates.push(startDate);
    return dates;
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
      } else {
        return false
      }
    }
  }
}
</script>
<style scoped>
#instalist-wrapper {
  width: 13vw;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
  #instalist-wrapper {
    width: 25vw;
  }

  .leaflet-popup-content {
    max-width: 60vw;
  }

  .leaflet-popup-content .insta-story {
    display: none;
  }
}


h4 {
  margin: 3px;
  background: #ddd;
  width: 100%;
}

#instalist-wrapper ul li {
  list-style-type: none;
  display: block;
  width: 100%;
  min-height: 1.2em;
  text-align: left;
  padding: 0.2em 5px;
  font-size: smaller;
}
#instalist-wrapper ul {
  background: #ddd;
  padding-left: 5px;
  margin: 0.2em;
}
#instalist-wrapper ul li:nth-child(even) {
  background: rgb(224, 243, 181)
  }
#instalist-wrapper ul li:nth-child(odd) {
  background: rgb(192, 181, 245)
  }
  #instalist-wrapper ul li p {
    background: #eee;
    padding: 0.4em 3px;
    font-size: smaller;
  }
  #instalist-wrapper ul li.active span {
    font-weight: bold;
  }
</style>